.loginPaper {
    padding: 30px; 
    border-radius: 8px;
    width: 50%;
    max-width: 650px;
    height: auto;
}

.loginPaper .PortonLogo {
    display:block;
    margin-left:auto;
    margin-right:auto;
    width:30%;
    height:30%;
    padding-bottom:2.5%;
}

.loginPaper .SignInTypography {
    margin-bottom: 5%;
    font-weight: 400;
    font-size: 28px;
}

.emailInput {
    margin-bottom:1.5%;
}

.passwordInput {
    margin-bottom:1%;
}

.loginPaper .forgotYourPasswordButton {
    margin-top:-3.5%;
    margin-bottom:5%;
}

.signInBox .circularProgress {
    color: '#1766b3';
}

.signInBox .signInButton {
    background-color:#EA8331;
    color:#fff;
    border-radius:7px;
}

.signInBox .signInButton:hover {
    background-color:#EA8331;   
}

.createAccountGrid .noAccountText {
    text-align:center;
    font-size:90%;
    width:100%;
    margin-top:2.5%;
}

.description{
    text-align: center;
    color: #767676;
}

.email{
    text-align: center;
    padding-bottom: 1rem;
}

.descriptionBot{
    text-align: center;
    color: #767676;
    padding-top: 1rem;
}

.invalidEmail{
    text-align: center;
    color: red;
    font-size: 0.5rem;
}