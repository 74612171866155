/* PullApp Status Drawer CSS Breakpoint Styles */

/* Extra-Small Screens (phones) */
@media only screen and (max-width:600px) {
    #orgName {
        font-size: 16px; font-weight: 600;
    }
}

/* Small Screens (portrait tablets, large phones) */
@media only screen and (min-width:600px) {
    
}

/* Medium Screens (landscape tablets) */
@media only screen and (min-width:960px) {
    
}

/* Large Screens (standard laptops/desktops) */
@media only screen and (min-width:1280px) {
    
}

/* Extra-Large Screens (large laptops/desktops) */
@media only screen and (min-width:1920px) {
    
}
